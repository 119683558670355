import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { FormControl, MenuItem } from '@mui/material';
import { getUserCertificates } from './services/api';
import { CertificateViewer } from './components/imageViewer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://codingx.app/">
        CodingX
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function App() {
  const handleSubmit = async (event) => {
    event.preventDefault();
    setButtonDisabled(true);
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const userCertificatesArray = await getUserCertificates(app, email);
    setimagesArr(userCertificatesArray);
    if(email && app && !userCertificatesArray.length){
      toast(`No certificates were issued for the email ID. Please verify if the email ID is correct & you have completed your course to unlock certificate.`);
    } else if(!email){
      toast('Please enter a valid email ID.');
    }
    setButtonDisabled(false);
  };

  const [app, setApp] = React.useState('CodingX');
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [imagesArr, setimagesArr] = React.useState([]);

  const handleSelectChange = (event) => {
    setApp(event.target.value);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={6}
          sx={{
            background: 'linear-gradient(to right bottom, #884ffe, #361182)'
          }}
        >
          <CertificateViewer images={imagesArr}/>
        </Grid>
        <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                sx={{ 
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: '#864dfc'
                    }
                  },
                  "& .MuiInputLabel-outlined.Mui-focused": {
                    color: "#6b29fe"
                  },
                  my: 2
                 }}
              />
              <Box sx={{ minWidth: 250 }}>
                <FormControl fullWidth>
                  <TextField
                    value={app}
                    id="app"
                    name="app"
                    label="App"
                    required
                    onChange={handleSelectChange}
                    select
                    sx={{ 
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: '#864dfc'
                        }
                      },
                      "& .MuiInputLabel-outlined.Mui-focused": {
                        color: "#6b29fe"
                      },
                     }}
                  >
                    <MenuItem value={"CodingX"}>CodingX</MenuItem>
                    <MenuItem value={"HackerX"}>HackerX</MenuItem>
                    <MenuItem value={"PythonX"}>PythonX</MenuItem>
                    <MenuItem value={"CX"}>CX</MenuItem>
                    <MenuItem value={"CppX"}>CppX</MenuItem>
                    <MenuItem value={"JavaX"}>JavaX</MenuItem>
                  </TextField>
                </FormControl>
              </Box>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={buttonDisabled}
                sx={{ mt: 3, mb: 2, backgroundColor: "#9f70fe",'&:hover': {
                  backgroundColor: '#864dfc'
              } }}
              >
                Get Certificate/s
              </Button>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <ToastContainer toastStyle={{ backgroundColor: "#9f70fe", color: "white" }} />
    </ThemeProvider>
  );
}
import axios from "axios";

const apiUrl = 'https://admin.codingx.app';

export const getUserCertificates = async (app, email) => {
  const headers = {
    "Content-Type": "application/json",
    app,
  };
  try {
    const response = await axios.get(
      `${apiUrl}/users/certificates?email=${email}`,
      { headers }
    );
    return response.data.data.map(certificate => certificate.imageUrl);
  } catch (e) {
    return [];
  }
};
